import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LaptopImg from "../../assets/home-main.svg";
import Tilt from "react-parallax-tilt";

const About = () => {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span style={{ fontFamily: 'Fredericka the Great, cursive' }}> About Me </span>
            </h1>
            <p className="home-about-body">
              Passionate software engineer currently pursuing Master's degree in Information Systems at <b className="yellow">Northeastern University</b>, Boston, MA.
              <br />
              <br />
              My specializations include
              <b className="yellow"> Full Stack Software Development </b> and <b className="yellow"> Cloud Computing </b>.
              <br />
              <br />I have 3+ years of work experience in <b className="yellow"> creating </b> and <b className="yellow"> deploying </b>web applications and have been involved in the complete <b className="yellow">software development lifecycle</b> process.
              <br />
              <br />
              Skilled in techonologies such as <b className="yellow">Java, Spring Boot, Javascript, Node.js, React.js, SQL and MongoDB.</b>
              <br />
              Proficient in utilising <b className="yellow">Git</b> for version control and <b className="yellow">AWS</b> for deploying and maintaing applications.
              <br />
              <br />
              Currently looking for full-time software development and DevOps <b className="yellow">internship/co-op</b> opportunities starting in <b className="yellow">Fall 2023</b>. 
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={LaptopImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
          <br />
        </Row>
      </Container>
    </Container>
  );

}

export default About