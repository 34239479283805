import React from 'react'
import { Card, Col, Container, Row } from "react-bootstrap";
import { AiFillGithub, AiFillMail, AiFillPhone } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { SiGooglemaps } from 'react-icons/si';

const Contact = () => {
  return (
    <Container style={{ padding: '60px' }}>
      <h1 className="project-heading" style={{ fontSize: "2.6em", color: 'white', marginTop: '65px', marginBottom: '20px' }}>
        <span className="yellow" style={{ fontFamily: 'Fredericka the Great, cursive' }}> Contact Me </span>
      </h1>


      <Container style={{ marginLeft: '5px', marginBottom: '80px' }}>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <Card className='contact-card'>
              <Card.Body>
                <Card.Title><SiGooglemaps /><b className="yellow"> Address</b></Card.Title>
                <Card.Text style={{ textAlign: "center" }}>
                  360 Riverway, Boston, MA, 02115
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="project-card">
            <Card className='contact-card'>
              <Card.Body>
                <Card.Title><AiFillMail /><b className="yellow"> E-Mail</b></Card.Title>
                <Card.Text style={{ textAlign: "center" }}>
                  <a href={'mailto:manickam.s@northeastern.edu'} style={{textDecoration: 'none', color: 'white'}}>manickam.s@northeastern.edu</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4} className="project-card">
            <Card className='contact-card'>
              <Card.Body>
                <Card.Title><AiFillPhone /><b className="yellow"> Mobile</b></Card.Title>
                <Card.Text style={{ textAlign: "center" }}>
                  +1 (857) 376-1918
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

        </Row>
      </Container>
      <Row>
        <Col md={12} className="home-about-social">
          <h3><b className="yellow">Connect </b>with me on</h3>
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                href="https://github.com/SubbuManickam"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
                aria-label="github"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/subbu-manickam/"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
                aria-label="linkedin"
              >
                <FaLinkedinIn />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Contact