import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../components/Projects/ProjectCard";
import marvel from "../assets/projects/Marvelflix.PNG"
import cicd from "../assets/projects/CI-CD.png";
import wordle from "../assets/projects/Wordle.PNG";
import ecommerce from "../assets/projects/Ecommerce.png";
import travelling from "../assets/projects/TravellingSalesman.jpg";
import resort from "../assets/projects/ResortManagement.PNG";
import Github from "../components/Skillset/Github";

const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading" style={{fontFamily: 'Fredericka the Great, cursive'}}>
           <span className="yellow">Recent Projects</span>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={marvel}
              isBlog={false}
              title="Marvelflix"
              description="A Netflix application clone exclusively for Marvel movies with options to filter based on movies, series and phase."
              ghLink="https://github.com/SubbuManickam/MarvelFlix"
              tech={["React.js, Node.js, Express.js, MongoDB, Sass"]}
              demoLink="https://marvel-flix-subbumanickam.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cicd}
              isBlog={false}
              title="Cloud Infrastructure - CI/CD"
              description="Built a Continuous Integration and Deployment pipeline in AWS for creating users, products and managing them."
              ghLink="https://github.com/SubbuManickam/CI-CD_Webapp"
              tech={["Java, Spring Boot, AWS, Terraform, Packer"]}
              ghLink2="https://github.com/SubbuManickam/CI-CD_AWSInfrastructure"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ecommerce}
              isBlog={false}
              title="E‑Mart"
              description="An Amazon clone exclusively for electronic gadgets which allows an user to add items to cart and pay on checkout."
              ghLink="https://github.com/SubbuManickam/E-Mart_E-Commerce"
              tech={["HTML, CSS, React.js, Node.js, MongoDB"]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={wordle}
              isBlog={false}
              title="Wordle"
              description="A Wordle application clone that generates a random 5-letter word everyday and provides the users 6 chances to guess the word successfully."
              ghLink="https://github.com/SubbuManickam/Wordle-Clone"
              tech={["React.js, Bootstrap"]}
              demoLink="https://wordle-clone-subbumanickam.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={travelling}
              isBlog={false}
              title="Travelling Salesman Problem"
              description="An optimized solution for the Travelling Salesman Problem utilising the Christofide's Algorithm and Simulated Annealing."
              ghLink="https://github.com/SubbuManickam/Travelling-Salesman-Optimizations"
              tech={["Java, Maven"]}
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={resort}
              isBlog={false}
              title="Resort Management System"
              description="A database management solution for a resort which allows users to book and track the avaiable facilities based on work requests."
              ghLink="https://github.com/SubbuManickam/Resort_Management_System"
              tech={["Java, Swing, DB4o"]}
            />
          </Col>
        </Row>
      </Container>
      <Github />
    </Container>
  )
}

export default Projects