import React from 'react'
import {
    VerticalTimeline,
    VerticalTimelineElement,
  } from "react-vertical-timeline-component";
  import { Container } from "react-bootstrap";
  import "react-vertical-timeline-component/style.min.css";
  import {
      SiAccenture, SiTarget
  } from "react-icons/si"
  import Badge from "react-bootstrap/Badge";
import { BsBook } from 'react-icons/bs';

  const Experience = () => {
  return (
    <Container style={{padding: '60px'}}>
      <h1 className="project-heading" style={{ fontSize: "2.6em", color: 'white', marginTop: '80px', marginBottom: '20px'}}>
                  <span style={{fontFamily: 'Fredericka the Great, cursive'}}> Career Path </span>
                </h1>
      <VerticalTimeline>
      <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2022"
            iconStyle={{
              background: "rgb(217, 220, 185)",
              color: "black",
              textAlign: "center",
            }}
            icon={<BsBook />}
            key={1}
          >
            <div style={{ textAlign: "left", marginBottom: "4px" }}>
              <b>Graduate Degree (MS)</b>
              <span> (09/2022 - 05/2024)</span>
            </div>

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              Information Systems
            </h3>
            <h5
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              Northeastern University
            </h5>
            <div style={{ textAlign: "left", marginTop: "15px" }}>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            React.js
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Node.js
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            AWS
            </Badge>
            </div>
          </VerticalTimelineElement>
      <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2022"
            iconStyle={{
                background: "rgb(217, 220, 185)",
                color: "black",
                textAlign: "center",
              }}
            icon={<SiAccenture />}
            key={2}
          >
            <div style={{ textAlign: "left", marginBottom: "4px" }}>
              <b>Full time employment</b>
              <span> (06/2019 - 08/2022)</span>
            </div>

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              Software Engineer
            </h3>
            <h5
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              Accenture
            </h5>
            <div style={{ textAlign: "left", marginTop: "15px" }}>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Java
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Spring Boot
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            REST APIs
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Git
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            MongoDB
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Azure
            </Badge>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2022"
            iconStyle={{
                background: "rgb(217, 220, 185)",
                color: "black",
                textAlign: "center",
              }}
            icon={<SiAccenture />}
            key={3}
          >
            <div style={{ textAlign: "left", marginBottom: "4px" }}>
              <b>Internship</b>
              <span> (01/2019 - 06/2019)</span>
            </div>

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              Software Developer Intern
            </h3>
            <h5
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              Accenture
            </h5>
            <div style={{ textAlign: "left", marginTop: "15px" }}>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
              SQL
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
              Alteryx
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
              JIRA
            </Badge>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2022"
            iconStyle={{
              background: "rgb(217, 220, 185)",
              color: "black",
              textAlign: "center",
            }}
            icon={<BsBook />}
            key={4}
          >
            <div style={{ textAlign: "left", marginBottom: "4px" }}>
              <b>Undergraduate Degree (BE)</b>
              <span> (08/2015 - 06/2019)</span>
            </div>

            <h3
              className="vertical-timeline-element-title"
              style={{ textAlign: "left" }}
            >
              Electronics and Communication
            </h3>
            <h5
              className="vertical-timeline-element-subtitle"
              style={{ textAlign: "left" }}
            >
              Anna University
            </h5>
            <div style={{ textAlign: "left", marginTop: "15px" }}>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            OOPS
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Networks
            </Badge>
            <Badge pill bg='' className="mr-2 mb-2 techBadge" style={{color: 'black', fontSize: '14px'}}>
            Operating Systems
            </Badge>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
              iconStyle={{
                background: "rgb(217, 220, 185)",
                color: "black",
                textAlign: "center",
              }}
              icon={<SiTarget/>}
              key={5}
            />
      </VerticalTimeline>
    </Container>
  )
}

export default Experience