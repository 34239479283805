import React from "react";
import { Container } from "react-bootstrap";

import Techstack from "../components/Skillset/Techstack";

const Skillset = () => {
  return (
    <Container fluid className="about-section">
      <Container>
        <h1 className="project-heading" style={{fontFamily: 'Fredericka the Great, cursive'}}>
          <span className="yellow">Professional Skillset</span>
        </h1>

        <Techstack />

        
      </Container>
    </Container>
  )
}

export default Skillset