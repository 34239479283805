import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from '../assets/home.png'
import Type from '../components/Home/Type';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiFillGithub } from 'react-icons/ai';

const Home = () => {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 className="heading-name">
                <strong className="main-name"> Subbu Manickam</strong>
              </h1>

              <div style={{ padding: 40, marginLeft: '5px', textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} >
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ height: "500px", marginLeft: "100px"}}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container style={{marginTop: '10px'}}>
      <Row>
              <Col md={12} className="home-about-social">
                <h3><b className="yellow">Connect </b>with me on</h3>
                <ul className="home-about-social-links">
                  <li className="social-icons">
                    <a
                      href="https://github.com/SubbuManickam"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                      aria-label="github"
                    >
                      <AiFillGithub />
                    </a>
                  </li>
                  <li className="social-icons">
                    <a
                      href="https://www.linkedin.com/in/subbu-manickam/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                      aria-label="linkedin"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
    </Container>
    </section>
  );
}

export default Home