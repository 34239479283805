import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiMongodb,
  SiJava,
  SiSpring,
  SiJavascript,
  SiReact,
  SiNodedotjs,
  SiMysql,
  SiGit,
  SiAmazonaws,
  SiTerraform,
  SiPacker,
  SiPostgresql,
  SiHtml5,
  SiCss3,
  SiSass,
} from "react-icons/si";

const Techstack = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiJava />
        <p className="iconName">Java</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpring />
        <p className="iconName">Spring Boot</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <p className="iconName">AWS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJavascript />
        <p className="iconName">Javascript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiReact />
        <p className="iconName">React.js</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNodedotjs />
        <p className="iconName">Node.js</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit />
        <p className="iconName">Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
        <p className="iconName">MySQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <p className="iconName">PostgreSQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMongodb />
        <p className="iconName">MongoDB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTerraform />
        <p className="iconName">Terraform</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPacker />
        <p className="iconName">Packer</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHtml5 />
        <p className="iconName">HTML</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCss3 />
        <p className="iconName">CSS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSass />
        <p className="iconName">SASS</p>
      </Col>
    </Row>
  );
}

export default Techstack;
