import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

const Github = () => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        <span className="yellow" style={{fontFamily: 'Fredericka the Great, cursive'}}>GitHub Statistics</span>
      </h1>
      <GitHubCalendar
        username="SubbuManickam"
        blockSize={15}
        blockMargin={5}
        color="gray"
        fontSize={16}
        style={{color: 'rgb(217, 220, 185)'}}
      />
    </Row>
  );
}

export default Github;
